export default {
  language: 'Language',
  newApartment: 'New Apartment',
  title: 'Title',
  ready_for_rent: 'Ready for rent',
  add_apartment: 'Add apartment',
  add_document_or_pages_to_work_with: 'Add document or pages to work with',
  create_quickly_templates:
    'Create templates to quickly create similar documents.',
  no_templates_yet: 'No templates yet',
  add_service_order: 'Add service order',
  add: 'Add',
  add_and_create_antoher: 'Add and create another',
  all: 'All',
  custom: 'Custom',
  // #-- Footer -----------
  open_all_branches: 'Open all branches',
  company_name: 'company name',
  branch_name: 'Branch name',
  // name_of_branch_office: 'beer sheba central branch',
  // #-- Apart_tree ------
  open_all: 'Open all',
  close_all: 'Close all',
  close: 'Close',
  simple_room: 'Simple room',
  // suite:'Suite',
  // #-- sub-menu building-in-management
  managed_buildings: 'Managed Buildings',
  // #-- sub-menu building
  quick_view: 'Quick view',
  // apartments: 'Apartments',
  tenants: 'Tenants',
  details: 'Details',
  communal: 'Communal',
  special_payments: 'Special payments',
  service_givers: 'Service givers',
  expenses: 'expenses',
  // documents: 'Documents',
  add_building: 'Add building',
  active_building: 'Active building',
  // #-- sub-menu (apartment)
  general: 'General',
  info: 'Info',
  additional_info: 'Additional information',
  advanced: 'Advanced',
  owner: 'Owner',
  documents: 'Documents',
  pictures: 'Pictures',
  house_payment: 'House Payment',
  renovations: 'Renovations',
  links: 'Links',
  history: 'History',
  // #-- sub menu (tenant)
  //
  guarantors: 'Guarantors',
  payments: 'Payments',
  messages: 'Messages',
  user: 'User',
  active_tenant: 'Active User',
  add_tenant: 'Add tenant',
  // #-- sub menu (tenancy)
  receipts: 'Receipts',
  // documents
  alerts: 'Alerts',
  // owner
  contract: 'Contract',
  check_in_out: 'Check-in/out',
  active_rent: 'Active rent',
  add_tenancy: 'Add tenancy',
  // ----------------------------------
  // - DBboards - menu
  // #-- apartments boards
  apartments_board: 'Apartments Board',
  // - Burger - menu
  // #-- main menu
  // dashboards: 'dashboards',
  buildings_management_module: 'Buildings management',
  assets: 'Assets',
  users: 'Users',
  lowercase_users: 'users',
  one_user: '1 user',
  tenancies: 'Tenancies',
  service_orders: 'Service orders',
  // check_in_out:'Check in/out',
  agents: 'Agents',
  rebuilding: 'Rebuilding',
  home: 'Home',
  //  messages: 'Messages',
  finance: 'Finance',
  trash: 'Trash',
  signiton_module: 'Sign IT On module',
  boards_module: 'Boards',
  // #-- second sub-menu - buildings management
  // quick_view: 'Quick view',
  communal_payments: 'Communal payments',
  common_palaces: 'Common palaces',
  bank_transfers: 'Bank transfers',
  service_orders_reports: 'service_orders reports',
  reports: 'Reports',
  custom_boards: 'Custom boards',
  // header,
  is_active: 'Is Active',
  // #-- third sub-menu - assets
  // assets:'assets',
  _add: 'Add',
  board: 'Board',
  group: 'Group',
  buildings: 'Buildings',
  // #-- forth sub-menu - users (userLists)
  user_lists: 'User lists',
  // tenants:'',
  candidates: 'Candidates',
  building_tenants: 'Building tenants',
  owners: 'Owners',
  broadcast_user_list: 'Broadcast user list',
  sms_receivers_board: 'SMS receivers board',
  mail_receivers_board: 'Mail receivers board',
  forms_receivers: 'Forms receivers',
  // - Burger - submenu (contexlist)
  add_user: 'New user',
  // add_building:'',
  add_check_in_out: 'Add check in out',
  new_document: 'New document',
  new_message: 'New message',
  new_expense: 'New expense',
  new_payment_receive: 'New payment receive',
  ask_for_payment: 'Ask for payment',
  invite_user: 'Invite user',
  send_document: 'send document',
  new_reservation: 'New reservation',
  new_task: 'New task',
  // page account
  photo: 'Photo',
  look: 'Look',
  delete: 'Delete',
  select: 'Select',
  // side-bar
  apartments_created: 'Apartments created',
  storage_used: 'Storage used',
  sms_used: 'SMS used',
  sms: 'SMS',
  call: 'Call',
  upgrade: 'Upgrade',
  upgrade_all: 'Upgrade all',
  get_free: 'Get free',
  get_for_free: 'Get for free',
  get_more_features_by_creating_organization:
    'Get more features by creating organization',
  create_for_free: 'Create for free',
  my_account: 'My account',
  organization: 'Organization',
  invite: 'Invite',
  invitations: 'Invitations',
  support: 'Support',
  log_out: 'Log out',
  // - buildings
  // - general
  // #-- address
  address: 'Address',
  building_address: 'Building address',
  state: 'State',
  city: 'City',
  neighborhood: 'neighborhood',
  street: 'Street',
  building: 'building',
  entry: 'entry',
  unique_name: 'Unique name',
  use_address_as_name: 'Use address as an identifier',
  apartments: 'Apartments',
  floors: 'Floors',
  elevators: 'Elevators',
  zip_code: 'ZIP Code',
  post_office_site: 'אתר מיקוד',
  // #-- payments
  // payments: 'Payments', уже есть
  building_is_managed: 'Building is managed',
  by_me: 'By me',
  by_organization: 'By organization',
  automatic_alerts: 'Automatic alerts',
  equal_payments: 'equal payments for all tenants',
  tAMA_interested: 'TAMA interested',
  communal_Fee: 'Communal Fee',
  pay_day: 'Pay day',
  snap_to_index: 'Snap to index',
  credit_card_tax_payment_3: 'Credit card tax payment 3%',
  // house keeper - Payment information
  house_keeper_Payment_information: 'house keeper - Payment information',
  receive_payments: 'receive payments',
  bank_account_owner: 'bank account owner',
  country: 'Country',
  bank_name: 'Bank name',
  branch: 'Branch',
  account_number: 'Account number',
  iban_id: 'IBAN ID',
  swift: 'SWIFT/BIC',
  balance: 'Balance',
  tenants_can_see_the_Balance: 'the tenants can see the Balance',
  // - apartment ----------------------
  // -- general ------------------------
  // #-- property
  asset: 'Asset',
  apartment_in_management: 'Apartment in management',
  type: 'Type',
  for_living: ' For living',
  office: 'Office',
  commercial: 'Commercial',
  hangar: 'Hangar',
  rent_as: 'Rent As',
  rent_as_info: 'the asset can be rent as...',
  apartment: 'Apartment',
  unit: 'Unit',
  room: 'room',
  suite: 'Suite',
  tenancy_status: 'Tenancy status',
  rented: 'Rented',
  searching_for_tenants: 'Searching for tenants',
  free: 'Free',
  management_status: 'Management status',
  accompanied_first_year: 'Accompanied first year',
  accompanied_second_year: 'Accompanied second year',
  accompanied_third_year: 'Accompanied third year',
  checking_year: 'Checking year',
  other: 'Other',
  number_in_the_local_authority: 'Number In the local authority',
  rooms: 'Rooms',
  suites: 'Suites',
  toilets: 'Toilets',
  units: 'Units',
  property_area: 'Property area',
  court_yard: 'Court yard',
  balcony: 'Balcony',
  parking_type: 'Parking type',
  doubled: 'Doubled',
  underground: 'Underground',
  on_ground: 'On ground',
  in_shadow: 'In shadow',
  // other:'',
  description_to_post: 'Description to post',
  // address:
  existing_building: 'Existing Building',
  // state
  // city
  // neighborhood
  // street
  // building
  apartment_num: 'Apartment number',
  at_floor: 'At floor',
  out_of: 'Out of',
  // elevators: 'Elevators',
  postal_code: 'Postal Code',
  // post_office_site: 'Post office site',
  distance: 'Distance',
  // #-- RentPayment
  rent_payment: 'Rent payment',
  payment_for_the_unit: 'Payment for the Unit',
  // snap_to_index: 'Snap to index',
  frequency: 'Frequency',
  payments_methods: 'Payments methods',
  payment_day: 'Payment day',
  checks: 'Checks',
  checks_must_be_accepted: 'Checks must be accepted',
  Last_check: 'Last check',
  in_a_non_standard_case: 'In a non-standard case',
  // #-- SafetyCheck
  list_is_empty: 'List is empty',
  you_should_add_from: 'You should add from',
  safety_check: 'Safety check',
  safeties_thats_required_for_rent: 'Safeties thats required for rent',
  the_safety_type: 'The safety type',
  receiver: 'Receiver',
  amount: 'Amount',
  valid_till: 'Valid till',
  status: 'Status',
  add_safety_check: 'Add safety check',
  // #-- apartment follower + manager
  maintenance: ' Maintenance',
  add_follower: 'Add follower',
  add_manager: 'Add manager',
  managers: 'Managers',
  followers: 'Followers',
  // #-- service order permissions
  service_order_permissions: 'Service order permissions',
  service_calls_that_can_be_opened: 'Service calls that can be opened',
  electricity: 'electricity',
  plummeting: 'plummeting',
  security: 'Security',
  password_is_invalid: 'Password is invalid',
  // -- additional --------------------
  // -- furniture --------------------
  add_list: 'Add list',
  Furniture_of_the_property: 'Furniture of the property',
  Furniture_lists_of_the_property: 'Furniture lists of the property',
  excel_file: 'Excel file',
  import: 'Import',
  export: 'Export',
  current_price: 'Current price',
  cost: 'Cost',
  purchase_date: 'Purchase date',
  item: 'Item',
  // -- more details --------------------
  more_details_about_the_property: 'More details about the property',
  renovated: 'Renovated',
  renovation_started: 'Renovation started',
  renovation_ended: 'Renovation ended',
  parking: 'Parking',
  shelter: 'Shelter',
  air_Conditioner: 'Air-Conditioner',
  access_for_disabled: 'Access for disabled',
  pandor_doors: 'Pandor doors',
  for_couples: 'for cuples',
  // elevators:'Elevators',
  Water_Heater: 'Water Heater',
  wiFi: 'WiFi',
  optical_Fiber: 'Optical Fiber',
  smoking: 'Smoking',
  kosher_kitchen: 'Kosher kitchen',
  bars: 'Bars',
  pets: 'Pets',
  // -- Meter Item (inner component) --------------------
  counter_type: 'Counter type',
  company_thats_owns_the_Meter: 'Company thats owns the Meter',
  property_number: 'Property number',
  meter_location: 'Meter location',
  last_call: 'Last call',
  current_call: 'Current call',
  name_of_the_user: 'User name',
  main_user_integration_api_key: 'Main user integration api key',
  // user:'user'
  responsible_for_the_meter: 'Responsible for the meter',
  payment_transfer_is_required: 'Payment transfer is required',
  request_a_power_of_attorney_for_automatic_transfer:
    'Request a power of attorney for automatic transfer',
  make_a_payer_transfer_automatically: 'Make a payer transfer automatically',
  archive_of_counter_readings: 'Archive of counter readings',
  transfer_Archive: 'Transfer Archive',
  call_gas_company: 'Call Cas company',
  // #-- check-in-out
  // ------ sub-menu (check-in-out)
  statuses: 'Statuses',
  asset_condition: 'Asset condition',
  furniture: 'Furniture',
  authorities: 'Authorities',
  summary: 'summary',
  summary_and_send: 'summary & send',
  check_done: 'Check is done',
  // ------ meters (check-in-out)
  end_the_process: 'End the process',
  water_meter: 'Water meter',
  gas_meter: 'Gas meter',
  electricity_meter: 'Electricity meter',
  municipality_meter: 'Municipality meter',
  is_a_power_of_attorney_for_automatic_transfer:
    'Is a power of attorney for automatic transfer',
  execute_the_transfer_process: 'execute the transfer process',
  // next: 'Next',
  save: 'save',
  keys: 'Keys',
  move_to_archive_at_the_end_of_the_process:
    'move to archive at the end of the proccess',
  // -- tenants ----------------------
  // -- assigned_property ----------
  assigned_property: 'Assigned property',
  asset_1: 'Asset 1',
  asset_2: 'Asset 2',
  // asset:'Asset',
  // -- Personal info ------------------------
  personal_info: 'Personal info',
  personal_information: 'Personal information',
  name: 'Name',
  // last_name: 'Last name',
  national_id: 'National id',
  tenant_type: 'tenant_type',
  share_to: 'Share to',
  // phone
  second_phone: 'Second phone',
  // mail
  gender: 'Gender',
  date_of_birth: 'Date of birth',
  not_valid: 'Not valid',
  // invite_user
  // upload_an_avatar: 'Upload an avatar',
  max_file_to_be_uploaded: 'max file that can be uploaded',
  identity_confirmation: 'Identity confirmation',
  identity_not_verified_you_can_do_it_later:
    'Identity not verified. You can do it later.',
  document_type: 'Document type',
  number: 'Number',
  date_and_time: 'Date and time',
  date_of_issue: 'Date of issue',
  // valid_till:'Valid till',
  driving_license: 'Driving license',
  passport_id: 'Passport id',
  // other
  // name
  another_license: 'Another license',
  add_other: 'Add other',
  add_passport: 'Add passport',
  add_license: 'Add license',
  add_national_ID: 'Add national ID',
  national_ID: 'National ID',
  // --- agents
  // agents:'Agents',
  agent_that_found_the_tenant: 'Agent that found the tenant',
  // --address
  old_address_of_the_user: 'Old address of the user',
  work: 'Work (optional)',
  work_sphere: 'Work sphere',
  Salary: 'Salary',
  // -- Aditional info ------------------------
  // -- company info ------
  company_information_: 'company information (optional)',
  company_subtitle_info: 'in case that the tenant is a company',
  check_out_the_company: 'Check out the company',
  company_name_: 'Company name',
  company_number: 'Company number',
  business_sector: 'Business sector',
  year_cycle: 'Year cycle',
  economic_strength: 'Economic strength',
  // - Auth ----------------------
  // -- signin
  redirecting_to_the_document: 'Redirecting to the document',
  redirecting_to_the_verification: 'Redirecting to identity verification',
  welcome_to_sign_fast: 'Welcome to Signy',
  first_we_need_to_be_sure_: 'first we need to be sure its you',
  this_number_is_not_registered: 'This number is not registered',
  this_email_is_not_registered: 'This email is not registered',
  please_try_one_more_time_:
    'Please, try one more time, the password or email is wrong.',
  email_is_already_registered: 'This email is already registered',
  number_is_already_registered: 'This phone number is already registered',
  sign_in: 'Sign in',
  skip_sign: 'Skip and Sign',
  skip_generate_password: 'Skip & Generate password',
  log_in: 'Log in',
  quick_log_in: 'Quick log in',
  account_export: 'Account export',
  reset_password: 'Reset password',
  name_of_branch_office: 'Name of branch office',
  export_my_data_in_csv: 'Export my data in CSV',
  birth_date: 'Birth date',
  image_of_organization: 'Image of organization',
  log_in_with: 'Log in with  ',
  sign_up_with: 'Sign up with',
  forgot_password: 'Forgot password',
  password: 'Password',
  email: 'Email',
  email_or_phone: 'email or phone',
  mail_you_registered_with: 'Mail you registered with',
  phone: 'Phone',
  stay_in: 'stay in',
  back_to_main_page: 'Back to main page',
  registration: 'Registration',
  see_feature_list: 'See feature list',
  redirecting_to_new_asset: 'Redirecting to create a new asset',
  welcome_to_RentIL: 'Welcome to RentIL!',
  click_here_if_you_dont_get_redirected:
    'Click here if you don’t get redirected',
  message_was_sent_to_your_company: 'Message was sent to your company',
  redirecting_to_home_page: 'Redirecting to home page',
  forgot_password_q: 'Forgot password?',
  create_password: 'Create Password',
  // registration:'',
  write_your_mail_or_phone_number_to_get_link_to_restore_your_password:
    'write your mail or phone number to get link for restoring your password',
  send: 'send',
  or_registration_by: 'Or registration by',
  repeat_password: 'Repeat password',
  back: 'Back',
  repeat: 'Repeat',
  change_Password: 'Change Password',
  apply: 'Apply',
  skip_and_go_to_home_page: 'Skip and go to home page',
  by_clicking_continue: 'By clicking “Continue” above, you agree to D.F group',
  terms_and_conditions: 'Terms and Conditions',
  and: 'and',
  privacy_policy: 'Privacy Policy',
  skip_and_sign: 'Skip & Sign',
  current_password: 'Current password',
  new_password: 'New password',

  // sign_fast_change
  the_right_: 'The right way to sign documents today',
  only_want_to_sign_a_document: 'Only want to sign a document',
  go_to_signy: 'Go to Signy',
  signy_advanced_PDF_viewer: 'Signy advanced PDF viewer',
  signy_advanced_PDF_creator: 'Signy advanced PDF creator',
  // -- choose role
  who_are_you: 'Who are you?',
  apartment_owner: 'Apartment owner',
  property_manager: 'Property manager',
  communal_building_manager: 'Communal building manager',
  property_management_company_CEO: 'Property management company CEO',
  property_agent: 'Property agent',
  // -- How do you manage the assets?
  how_do_you_manage_the_assets_q: 'How do you manage the assets?',
  managing_by_myself: 'Managing by myself',
  company_is_managing_for_me: 'Company is managing for me',
  // -- CompanyInvitation
  invite_the_company_to_enhance_clarity_between_you:
    'Invite the company to enhance clarity between you',
  existing_company: 'Existing company',
  new_company: 'New company',
  ask_company_to_manage_my_assets: 'Ask company to manage my assets',
  company_email: 'Company email',
  company_phone: 'Company phone',
  share_link: 'Share link',
  scheduled_send: 'Scheduled Send',
  big_send: 'Send',
  // -- email_sent
  weve_sent_you_an_email: 'We’ve sent you an email',
  go_to_your_l_to_open_the_link: 'Go to your  email to open the link',
  // or: 'Or',
  paste_here_6_digits_from_SMS_email_you_received:
    'Paste here 6 digits from SMS/email you received',
  click_here_if_you_dont_receive_the_email:
    'Click here if you dont receive the email',
  no_drafts: 'No Drafts',
  inbox_is_empty: 'Inbox is empty',
  nothing_was_sent: 'Nothing was sent',
  here_will_be_the_drafts:
    'All the files you start to edit but don’t send will be here. Upload a file by the plus icon above',
  here_will_be_the_sent: 'All the files that were sent to you will be here.',
  here_will_be_you_sent: 'All the files that you send will be here.',
  arch_empty: 'Archive is empty',
  here_will_be_archive: 'will be the files you archived.',
  nothing_was_deleted: 'Nothing was deleted',
  here_will_be_deleted:
    'Here will be the files that you have deleted. They are stored for 30 days and then disappear from the system.',
  no_actions: 'No actions required',
  here_will_actions:
    'There will be files here that require your action to move on.',
  no_waiting: 'No one to wait for',
  here_will_waiting:
    "There will be files here, require other people's actions to move on.",
  no_urgent: 'There is nothing urgent',
  here_will_urgent:
    'There will be files that need to be signed in less than 5 business days.',
  no_for_government: 'There is nothing for the goverment',
  here_will_for_government:
    'Here will be the files in which you interact with civil servants.',
  no_completed: 'No completed files',
  here_will_completed:
    'Here will be the files that have been signed by all participants.',
  no_custom: 'No files in «Custom» folder',
  here_will_custom: 'Here will be the files that you have marked as “Custom”.',
  drafts: 'Drafts',
  inbox: 'Inbox',
  sent: 'Sent',
  archive: 'Archive',
  actions_required: 'Action required',
  waiting_for_others: 'Waiting for others',
  expiring_soon: 'Expiring soon',
  for_government: 'For government',
  completed: 'Completed',
  folder: 'Folder',
  file_storage: 'File storage',
  export_html: 'Export HTML',
  duration: 'Duration',
  attach_file: 'Attach file',
  size_any_type: '50mb, any type',
  optional_informaition: 'Optional information',
  view_plans: 'View Plans',
  plans: 'Plans',
  to_change_fields_types_:
    'To change fields types, properties and connect them to CMS.',
  you_must_fill_out_all_: 'You must fill out all required fields',
  contact_information: 'Contact Information',
  user_color: 'User color',
  validation_type: 'Validation type',
  no_validation: 'No validation',
  password_protection: 'Password protection',
  remind_time: 'Remind time',
  cannot_edit_document_opened: 'Edit is forbidden, document was opened',
  document_will_expire_at: 'Document will expire at:',
  all_users_fill_out_the_single_origin_of_the_document:
    'All users fill out the single origin of the document',
  single_message_for_all: 'Single message for all',
  personal_messages: 'Personal messages',
  sms_notifications: 'SMS notifications',
  automatically_send_sms:
    'Automatically send SMS to recipients and author during the whole process after sending the document',
  notification_language: 'Notification language',
  system_language: 'System Language',
  free_resources_left: '%%% of free resources left',
  default_document_settings: 'Default Document Settings',
  profile: 'Profile',
  saved: 'Saved',
  organizations: 'Organizations',
  organizations_general_tab: 'General',
  organizations_branches_tab: 'Branches',
  organizations_add_branch: 'Add branch',
  organizations_add_branch_name: 'Branch name',
  you_can_not_create_a_organization_:
    'You can not create a organization, you already have one',
  you_can_not_create_a_organization_for_now:
    'You can not create a organization for now',
  if_you_want_contact_us: 'if you want so, please write us',
  name_of_your_company_is: 'name of your company is',
  // editor settings
  settings: 'Settings',
  add_photo: 'Add photo',
  type_name: 'Type name',
  visa_card: 'Visa card',
  changes_are_saved_automatically: 'Changes are saved automatically',
  expiration: 'Expiration',
  expiration_date: 'Expiration date',
  document_will_expire_in: 'Document will expire in',
  reminders: 'Reminders',
  send_a_reminder_: 'Send a reminder to the participant every',
  notification: 'Notifications',
  color_the_tab: 'Color the tab?',
  you_will_recieve_email_:
    'You will receive an email notification when a signer has signed the document',
  uuid_recomeded_: 'UUID (recommended to be turned on)',
  id_number: 'ID number',
  show_unique_:
    'Show the Uniqeu Identifier code which appears below the signatures. It is recommended that you keep this activated so that the signature of a signer can be validated using the Audit Trail,',
  signature_verification_:
    'Signature verification code (recommended to be turned on)',
  digitally_verify_:
    'Digitally verify the integrity of the printed document using a QR code and a unique password that are provided in the Audit Trail.',
  email_customization: 'email customization',
  email_branding: 'Email branding',
  include_logo:
    'Include your logo and company name in the emails. Accepted image formats: PNG, JPG, SVG.',
  logo: 'Logo',
  drug_and_drop: 'Drug and drop',
  or_upload_a_file: 'or upload a file',
  recommended_size_: 'Recommended size 300x150 px',
  two_factor_auth_: 'Two-factor authentication, if user is logged in',
  video_of_user_: 'Video of user telling the control phrase',
  selfie_with_own_id: 'Selfie with own ID',
  signature_type: 'Signature type',
  add_recipients_who_: 'Recipients are not specified',
  // editor left panel
  show_all: 'Show all',
  show_less: 'Show less',
  recipients: 'Recipients',
  text: 'Text',
  signature: 'Signature',
  date: 'Date',
  already_created: 'Already created',
  placeholder: 'Placeholder',
  hint: 'Hint',
  letters_quantity: 'Letters quantity',
  dropdown_options: 'Dropdown options',
  upgrade_to_pro_now: 'Upgrade to pro now',
  dropdown: 'Dropdown',
  attachment: 'Attachment',
  checkbox: 'Checkbox',
  radiobutton: 'Radiobutton',
  // ToolsEditorPanel
  simple: 'Simple',
  digital: 'Digital',
  fields: 'Fields',
  required: 'Required',
  // SignatureModal
  draw: 'Draw',
  upload: 'Upload',
  type_signature: 'Letters',
  lowercase_upload: 'upload',
  delete_all_signatures: 'Delete all signatures',
  apply_and_sign: 'Apply and sign',
  buy_more_signatures: 'Buy more signatures',
  creating_your_signature: 'Creating your signature',
  move_contact_to_group: 'Move contact to group',
  move_contact_to_active: 'Move contact to active',
  move_contacts_to_active: 'Move contacts to active',
  move_contact_to_archive: 'Move contact to archive',
  move_contacts_to_archive: 'Move contacts to archive',
  delete_contact: 'Delete contact',
  // editor right panel
  assign_all_recipients_to_all_fields: 'Assign all recipients to all fields',
  all_fields_: 'Add and assign to all fields',
  all_fields: 'All fields',
  sign_order: 'Sign order',
  remove_all_recipients_from_all_fields:
    'Remove all recipients from all fields',
  add_from_google_contacts: 'Add from Google Contacts',
  add_me_to_all_fields: 'Add me to all fields',
  remove_me_from_all_fields: 'Remove me from all fields',

  recipient: 'Recipient',
  add_me: 'Add me',
  recipients_are_those_:
    'All recipients with the link would open the document, if you make it public. Specify the signers if you want them to see only their fields.',
  you: 'You',
  validator: 'Validator',
  persons_should_sign_: 'persons should sign in the same time',
  new: 'New',
  add_all_from_search: 'Add all from search',
  // signed document
  system_accept_only_: 'The system accept only pdf format',
  file_size_should_be_: 'File size should be min 200MB',
  document_signed: 'Document signed',
  a_file: 'a file',
  download_a_copy_signed_: 'Download a copy of signed file',
  copy_link_to_file: 'Copy link to file',
  create_document_to_sign: 'Create document to sign',
  create_empty: 'Create empty',
  you_can_find_this_:
    'You can find this signed document in Documents > Completed page',
  hello_you_got_to_sign_fast_:
    'Hello! You got to Signy! To sign your document please sign in or register',
  //ContextHeaderPrivate
  public: 'Public',
  anyone_with_the_link_: 'Anyone with the link can open the document',
  private: 'Private',
  only_listed_recipients_: 'Only listed recipients can open the document',
  sharing: 'Sharing',
  //empty
  no_drafts_yet: 'No drafts yet',
  drafts_text:
    'Here will be the files you started to edit and didn’t send. Upload and edit file here',
  //headerSignFast
  url: 'URL',
  document_settings: 'Document settings',
  coming_soon: 'Coming soon',
  from_drive: 'From drive',
  from_device: 'From device',
  document_template: 'Document template',
  preview: 'Preview',
  previous_page: 'Previous Page',
  next_page: 'Next page',
  fit_to_width: 'Fit to width',
  fit_to_height: 'Fit to height',
  preview_for_users: 'Preview for users',
  //subhaderEditor
  zoom_in: 'Zoom in',
  zoom_out: 'Zoom out',
  // Signy
  signy: 'Signy',
  document_is_protected_:
    'Document is protected. Please enter the password you received',
  this_code_were_given_to_you_:
    'this code were given to you by the owner of this document, please contact him if you have some question',
  you_were_invited_to_sign_: 'You were invited to sign the document till',
  video_validation: 'Video validation',
  please_record_yourself_by_telling_:
    'please record yourself by telling the next words:“Digital signature”',
  national_id_verification: 'National ID verification',
  this_id_its_temporary_once_:
    'this id its temporary once it will be opened its till deleted from our server Only the document owner will check it and delete then.',
  national_id_photo: 'National ID photo',
  selfie_with_document: 'selfie with document',
  // uploaded: 'Uploaded',
  sms_validation: 'SMS Validation',
  send_verification_code: 'Send verification code',
  we_sent_you_an_sms_to: "We've sent you an sms to",
  resend_code: 'resend code',
  please_enter_some_details_about_yourself:
    'Please enter some details about yourself',
  this_image_as_valid_as_considered_:
    'This image as valid as considered originals to the extent allowed by applicable law.',
  by_clicking_next_above_:
    'By clicking “next” above, you agree that you sow the attachments included ',
  ask_for_review: 'Ask for a review',
  decline_document: 'Decline document',
  try_to_create_a_document_:
    'Try to create a document in our system and get 10 for free',
  enjoyed_the_experience: 'Enjoyed the experience?',
  declining: 'Declining',
  if_dimension_is_dropdown_it_:
    'If dimension is dropdown, it will drop upwards (fly), or it should be placed weird, aside from the text frame,',
  registration_page: 'Registration page',
  document_design_fg_: 'Document design.fg, 15 mb',
  open_link: 'Open link',
  document_fields: 'Document fields',
  //signy contacts
  i_dont_know_yet: 'I don`t know yet',
  // explorer => contacts
  field_id: 'Field Id',
  contacts: 'Contacts',
  make_favorite: 'Make favorite',
  remove_favorite: 'Remove favorite',
  add_contact: 'Add contact',
  contact: 'Contact',
  new_contact: 'New contact',
  use_this_number_for_whatsapp: 'Use this number for Whatsapp',
  telegram: 'Telegram',
  style: 'Style',
  reason_for_declining: 'Reason for declining',
  groups: 'Groups',
  signing_order: 'Signing order',
  group_members: 'Group members',
  import_csv: 'Import CSV',
  from_google: 'From Google',
  create: 'Create',
  open_from_device: 'Open from device',
  ddyymm: 'DD.YY.MM',
  ddmmyy: 'DD/MM/YY',
  first_name: 'First name',
  sur_name: 'Surname',
  last_name: 'Last name',
  add_at_least_one_recipient_:
    'pay attention that you should add fields to the document and add the a recipients, but you can send also a empty doc to digitally sign',
  upload_contacts_from_csv: 'Upload contacts from CSV',
  upload_my_contacts: 'Upload my contacts',
  upload_an_avatar: 'Upload an avatar',
  download_csv_template: 'Download CSV template',
  google_contact_migration: 'Google contact migration',
  add_contacts_to_employees_: 'Add contacts to Employees group',
  custom_group: 'CUSTOM GROUP',
  all_contacts: 'All Contacts',
  google_contacts: 'Google contacts',
  my_team: 'My team',
  deleted: 'Deleted',
  add_groups_to_employees_: 'Add groups to Employees contact',
  all_groups: 'All groups',
  employees: 'Employees',
  uploaded: 'Uploaded',
  farovites: 'Favorites',
  delete_group: 'Delete group',
  delete_branch: 'Delete Branch',
  edit_branch: 'Edit Branch',
  field_is_required: 'Field id required',
  no_contacts_yet: 'No contacts yet',
  no_contacts: 'No contacts',
  not_agree_with_content: 'Not agree with content',
  something_is_missing: 'Something is missing',
  document_name: 'Document Name',
  you_can_upload_your_contacts_: 'You can upload your contacts from',
  whatsapp: 'Whatsapp',
  telegram_username: 'Telegram username',
  mail: 'Mail',
  favorite: 'Favorite',
  group_created: 'Group created',
  all_documents: 'All documents',
  responsibles: 'Responsibles',
  rows_selected: 'rows selected',
  select_move: 'Select move',
  forms: 'Forms',
  use_template: 'Use template',
  created: 'Created',
  error_occurred_: 'Error occurred ! Invalid document!',
  // attachments: 'Attachments',
  dublicate: 'Dublicate',
  edit: 'Edit',
  // active: 'Active',
  no_teams: 'No Teams',
  no_uploaded: 'No uploaded',
  no_archieve: 'No archieve',
  no_attachments: 'No attachments',
  drop: 'Drop',
  pdf_file: 'PDF file',
  here: 'here',
  FOLDERS: 'FOLDERS',
  search_table: 'Search table',
  search_users: 'Search users',
  viewed: 'Viewed',
  signers: 'Signers',
  signing_status: 'Signing Status',
  custom_folders_forms: 'Custom folders',
  new_folder: 'New folder',
  // valid_till: 'Valid till',
  // archive: 'Archive',
  // deleted: 'Deleted',
  send_to_sign: 'Send to sign',
  archive_document: 'Archive document',
  delete_document: 'Delete document',
  creator_name: 'Creator Name',
  no_signatories: 'No signatories',
  sign: 'Sign',
  user_settings: 'User Settings',
  download: 'Download',
  download_now: 'Download',
  loading: 'loading...',
  checkbox_list: 'Checkbox list',
  radiobutton_list: 'Radiobutton list',
  reject: 'Reject',
  role: 'Role',
  comming_soon: 'coming soon',
  not_recieved: 'Not received',
  // sent: 'Sent',
  not_sent: 'Not sent',
  opened: 'Opened',
  read: 'Read',
  cancel: 'Cancel',
  // preview: 'Preview',
  pending: 'Pending',
  signed: 'Signed',
  // completed: 'Completed',
  // cancelled: '',
  asked_for_review: 'Asked for review',
  rejected: 'Rejected',
  // file => id => checking
  document: 'Document',
  form: 'Form',
  message: 'Message',
  checking: 'Checking',
  share: 'Share',
  message_for_all: 'Message for all',
  pdf_viewer: 'Pdf Viewer',
  lowercase_fields: 'fields',
  all_fields_are_filled: 'All fields are filled',
  initials: 'Initials',
  dimensions: 'Dimensions',
  finished_steps: 'Finished steps',
  next_steps: 'Next steps',
  deadline: 'Deadline',
  vacation: 'Vacation',
  figma_file: 'Figma file',
  party_address: 'Party address',
  rating: 'Rating',
  device: 'Device',
  google_drive: 'Google Drive',
  from_template: 'From template',
  // desktop default
  templates: 'Templates',
  dashboards: 'Dashboards',
  you_must_fill_out_all_required_field: 'You must fill out all required fields',
  next: 'Next',
  sample: 'Sample',
  // editor
  email_address_you_entered_: 'Email address you entered is invalid',
  its_need_to_be_a_valid_: 'Its need to be a valid Israel id format',
  clear_sign: 'Clear sign',
  // right panel
  pen: 'Pen',
  assign_all_recipients_: 'Assign all recipients to all fields?',
  it_cannot_be_toggled_back_:
    'It cannot be toggled back. To assign different signers again, you will have to edit fields manually.',
  remove_all_recipients_: 'Remove all recipients from all fields?',
  they_will_stay_in_the_:
    'They will stay in the list, but will not be assigned to any field.',
  drag_recipient_to_add_: 'Drag recipient to add a queue',
  all_recipients_were_removed_: 'All recipients were removed from all fields',
  drag_and_drop: 'Drag and drop',
  // settings
  all_users_fill_out_the_single:
    'All users fill out the single origin of the document',
  english: 'English',
  hebrew: 'Hebrew',
  // company_name: 'Company name',
  remove_group: 'Remove group',
  remove_recipient: 'Remove recipient',
  assign: 'Assing',
  remove: 'Remove',
  viewer: 'Viewer',
  or: 'or',
  // validator: 'Validator',
  signer: 'Signer',
  validator_will_review_:
    'The validator will review the document content and can validate or revoke the signature request',
  // validator_will_review_: 'The validator will review the document content and can validate or revoke the signature request',
  viewer_recieve_a_copy_:
    'The viewer recieve a copy of the original document and the completely signed one as well but do not need to take action on it',
  validator_caps: 'VALIDATOR',
  signer_caps: 'SIGNER',
  viewer_caps: 'VIEWER',
  these_preferences_will_apply_:
    'These preferences will apply to all participants in the document. You can change personal security settings from the recipient`s list.',
  password_was_generated_:
    'Password was generated automatically. You can type yours instead',
  passcode_should_be_minimum_: 'Passcode should be minimum 4 numbers',
  create_qr: 'Create QR',
  link_reciviers: 'Link reciviers',
  qr: 'QR',
  email_or_phone_required: 'Email or phone required',
  // user: 'user',
  // created: 'created',
  contact_is_favorite: 'Contact is now favorite',
  contact_is_not_favorite: 'Contact is not favorite now',
  contacts_updated: 'contacts updated',
  contacts_moved: 'contacts moved successfully',
  document_was_downloaded_: 'Document was downloaded automatically',
  error_document_was_protected:
    'The pdf is protected by password we can not open it',
  invalid_code: 'Invalid code',
  clear_all: 'Clear all',
  color_select: 'Color select',
  success: 'Success',
  enter_using: 'Enter using',
  add_group: 'Add group',
  create_field: 'Create Field',
  fields_summary: 'Fields Summary',
  drawing: 'Drawing',
  fields_in_the_doc: 'Fields in the doc',
  pdf_docx: 'PDF, DOCX',
  no_groups_yet: 'No groups yet',
  language_changed: 'Language changed',
  folder_created: 'Folder created',
  document_updated: 'Document was updated',
  documents_updated: 'documents were updated ',
  all_your_contacts_: 'All your contacts were successfully synchronized',
  all_recipients_were_: 'All recipients were removed from all fields',
  copied_private: 'The private link was copied',
  copied_general_code: 'General code was copied',
  text_input: 'Text input',
  date_input: 'Date input',
  // password
  password_is_required: 'Password is required',
  password_has_to_include: 'Password has to include',
  between_8_and_20: 'Between 8 and 20 characters',
  at_least_one_lowercase_English_:
    'At least one lowercase English character (a-z)',
  at_least_one_uppercase_English_:
    'At least one lowercase English character (A-Z)',
  at_least_one_specific_character:
    'At least one special character (!@#$%^&*()-+/{})',
  do_not_use_password_that_: 'Do not use the password, that you used before',
  attention: 'Attention',
  do_not_use_the_date_of_the_:
    'Do not use the birth date, ID number of telephone number',
  do_not_repeat_a_character_more_:
    'Do not repeat a character more than twice in the row',
  at_least_one_number: 'At least one number (0-9)',
  dear_user_due_to_many_attempts_:
    'Dear user, due to many attempts to connect to the system, the login was blocked for about 5 minutes',
  // sign_in: 'Sign In',
  we_have_sent_you_as_SMS_: 'We have sent you an SMS code to ***',
  we_have_sent_you_as_EMAIL_: 'We have sent you an EMAIL code to ***',
  _download_the_csv_template:
    '1. Download the template. 2. Fill in the template in the same way that the sample data is filled in. 3. Click on File, then Save as. Select CSV UTF-8 (comma delimited) (*csv) and save the file.',
  save_as_template: 'Save as template',
  open_editor: 'Open editor',
  attachments: 'Attachments',
  unlock: 'Unlock',
  cancelled: 'Cancelled',
  undo: 'Undo',
  redo: 'Redo',
  print: 'Print',
  shared: 'Document shared to the recipients',
  invert_background: 'Invert background',
  scheduleShared: 'Document scheduled to share',
  chooseSignerMethods: 'Please choose sending method for signers',
  add_signatories: 'Add signatories to document first',
  copy_qr_and_link: 'Copy QR and link',
  active_templates: 'Active templates',
  active: 'Active',
  copy: 'Copy',
  tags: 'Tags',
  show_lables_in_document: 'Show labels in document',
  uppercase_signing_status: 'SIGNING STATUS',
  uppercase_groups: 'GROUPS',
  last_sharing: 'Last sharing',
  shared_with: 'Shared with',
  adding_rows_from_database: 'Adding rows from database',
  create_document: 'Create Document',
  exit_preview: 'Exit preview',
  // not-allowed
  view_this_document: 'You are not allowed to view this document',
  to_get_access: 'Contact the document owner to get access.',
  create_your_document: 'Create your document',
  create_your_signature: 'Create your signature',
  // signed-document page
  already_signed: 'You have already signed this document',
  //modal
  documentFields: '{length} / {index} fields',
  documentFieldsList: '{count} fields in the document',

  all_signers: 'All signers',
  this_input_is_required: 'This input is required *',
  email_address_you_entered_is_invalid: 'Email address you entered is invalid',
  its_need_israel_id_format: 'Its need to be a valid Israel id format',
  phone_is_invalid: 'Phone is invalid',
  email_value_is_not_valid: 'Value is not a valid email address',
  is_integrated_with_third_party: 'integrated with third party',
  you_received_doc_sign: 'You received a document to sign!',
  continue_with: 'continue with',
  continue: 'Continue',

  //settings Tabs
  files: 'Files',
  invalid_otp_code: 'Invalid otp code',

  no_internet_connection: 'No internet connection',

  //errors
  an_error_occurred: 'An error occurred',
}
