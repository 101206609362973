export default {
  language: 'שפה',
  newApartment: 'דירה חדשה',
  title: 'כותרת',
  ready_for_rent: 'מוכן להשכרה',
  add_apartment: 'הוסף דירה',
  add_document_or_pages_to_work_with: 'הוסף מסמכים כדי להתחיל לעבוד איתם',
  no_templates_yet: 'No templates yet',
  create_quickly_templates:
    'Create templates to quickly create similar documents.',
  add_service_order: 'פתח קריאת שירות',
  add: 'לְהוֹסִיף',
  add_and_create_antoher: 'צור והוסף עוד סניף',
  name_of_the_user: 'שם המשתמש הראשי',
  all: 'כל',
  custom: 'Custom',
  // #-- Footer -----------
  open_all_branches: 'פתח רשימת סניפים',
  company_name: 'שם חברת ההשכרה',
  branch_name: 'שם הסניף',
  // name_of_branch_office: 'beer sheba central branch',
  // #-- Apart_tree ------
  open_all: 'פתח הכל',
  close_all: 'סגור הכל',
  close: 'סגור',
  simple_room: 'חדר',
  // suite:'Suite',
  // #-- sub-menu building-in-management
  managed_buildings: 'בניינים מנוהלים',
  // #-- sub-menu building
  quick_view: 'מבט מהיר',
  // apartments: 'Apartments',
  tenants: 'דיירים',
  details: 'פרטים',
  communal: 'ועד בית',
  special_payments: 'תשלומים מיוחדים',
  service_givers: 'ספקים',
  expenses: 'הוצאות',
  // documents: 'Documents',
  add_building: 'הוסף בניין',
  active_building: 'בניין פעיל',
  // #-- sub-menu (apartment)
  general: 'כללי',
  info: 'אינפורמציה',
  additional_info: 'מידע נוסף',
  advanced: 'מתקדם',
  owner: 'בעלים',
  documents: 'מסמכים',
  pictures: 'תמונות',
  house_payment: 'תשלומי ועד בית',
  renovations: 'שיפוצים',
  links: 'קישורים',
  history: 'היסטוריה',
  // #-- sub menu (tenant)
  //
  guarantors: 'ערבים',
  payments: 'תשלומים',
  messages: 'הודעות',
  user: 'משתמש',
  active_tenant: 'משתמש פעיל',
  add_tenant: 'הוסף שוכר',
  // #-- sub menu (tenancy)
  receipts: 'קבלות',
  // documents
  alerts: 'התראות',
  // owner
  contract: 'חוזה',
  check_in_out: 'כניסת ויציאת דיירים',
  active_rent: 'השכרה פעילה',
  add_tenancy: 'השכרה חדשה',
  // ----------------------------------
  // - DBboards - menu
  // #-- apartments boards
  apartments_board: 'לוח דירות',
  // - Burger - menu
  // #-- main menu
  dashboards: 'דשבורד',
  buildings_management_module: 'ניהול מבנים',
  assets: 'נכסים',
  users: 'משתמשים',
  lowercase_users: 'משתמשים',
  one_user: 'משתמש 1',
  tenancies: 'השכרות',
  service_orders: 'קריאות שירות',
  // check_in_out:'Check in/out',
  agents: 'סוכנים',
  rebuilding: 'שיפוצים',
  home: 'בית',
  //  messages: 'Messages',
  finance: 'פיננסים',
  trash: 'סל מחזור',
  signiton_module: 'ניהול טפסים',
  boards_module: 'לוחות',
  // #-- second sub-menu - buildings management
  // quick_view: 'Quick view',
  communal_payments: 'תשלום וועד בית',
  common_palaces: 'מוקמות משותפים',
  bank_transfers: 'העברות בנקאיות',
  service_orders_reports: 'דוחות קריאות שירות',
  reports: 'דוחות',
  custom_boards: 'לוחות משתמש',
  // header,
  is_active: 'פעיל',
  // #-- third sub-menu - assets
  // assets:'assets',
  _add: 'הוסף',
  board: 'לוח',
  group: 'קבוצה',
  buildings: 'בניינים',
  // #-- forth sub-menu - users (userLists)
  user_lists: 'רשימות משתמשים',
  // tenants:'',
  candidates: 'מועמדים',
  building_tenants: 'דיירי בניינים',
  owners: 'בעלים',
  broadcast_user_list: 'רשימות הפצה',
  sms_receivers_board: 'רשימת מקבלי SMS',
  mail_receivers_board: 'רשימת מקבלי מיילים',
  forms_receivers: 'רשימת מקבלי מסמכים',
  // - Burger - submenu (contexlist)
  add_user: 'הוסף משתמש',
  // add_building:'',
  add_check_in_out: 'אירוע החלפת דיירים',
  new_document: 'מסמך חדש',
  new_message: 'הודעה חדשה',
  new_expense: 'הוצאה חדשה',
  new_payment_receive: 'דווח קבלת תשלום',
  ask_for_payment: 'בקש תשלום',
  invite_user: 'הזמן משתמש',
  send_document: 'מסמך חדש',
  new_reservation: 'שריון חדש',
  new_task: 'משימה חדשה',
  // page account
  photo: 'תמונה',
  look: 'צפה',
  delete: 'מחק',
  select: 'בחר',
  // side-bar
  apartments_created: 'דירות נוצרו',
  storage_used: 'אחסון',
  sms_used: 'SMS שנותרו',
  upgrade: 'שדרג',
  upgrade_all: 'שדרג הכל',
  get_free: 'קבל בחינם',
  get_for_free: 'קבל חינם',
  get_more_features_by_creating_organization:
    'קבל פיצרים חדשים ע"י הקמת חברה חדשה',
  create_for_free: 'צור בחינם',
  my_account: 'הפרופיל שלי',
  organization: 'ארגון',
  invite: 'הזמן',
  invitations: 'הזמנות',
  support: 'תמיכה',
  log_out: 'התנתק',
  // - buildings
  // - general
  // #-- address
  address: 'כתובת',
  building_address: 'כתובת הבניין',
  state: 'מדינה',
  city: 'עיר',
  neighborhood: 'שכונה',
  street: 'רחוב',
  building: 'מספר בניין',
  entry: 'כניסה',
  unique_name: 'שם ייחודי',
  use_address_as_name: 'השתמש בכתובת כשם ייחודי',
  apartments: 'דירות',
  floors: 'קומות',
  elevators: 'מעליות',
  zip_code: 'מיקוד',
  post_office_site: 'אתר מיקוד',
  // #-- payments
  // payments: 'Payments', уже есть
  building_is_managed: 'הבניין בניהול',
  by_me: 'שלי',
  by_organization: 'חברת ניהול',
  automatic_alerts: 'התראות אוטומטיות',
  equal_payments: 'תשלום שווה לכל הדיירים',
  tAMA_interested: 'מתעניינים בתמ"א',
  communal_Fee: 'תשלום ועד בית',
  pay_day: 'יום תשלום',
  snap_to_index: 'הצמד למדד',
  credit_card_tax_payment_3: 'עמלת כרטיס אשראי 3%',
  // house keeper - Payment information
  house_keeper_Payment_information: 'פרטי תשלום וועד הבית',
  receive_payments: 'מקבל תשלומים לחשבון',
  bank_account_owner: 'שם בעל החשבון',
  country: 'מדינה',
  bank_name: 'מספר בנק',
  branch: 'סניף',
  account_number: 'מספר חשבון',
  iban_id: 'IBAN ID',
  swift: 'SWIFT/BIC',
  balance: 'יתרה בחשבון',
  tenants_can_see_the_Balance: 'השוכרים יכולים לראות את יתרת החשבון',
  // - apartment ----------------------
  // -- general ------------------------
  // #-- property
  asset: 'נכס',
  apartment_in_management: 'הנכס בניהול',
  type: 'סוג',
  for_living: 'מגורים',
  office: 'משרדי',
  commercial: 'מסחרי',
  hangar: 'האנגר',
  rent_as: 'השכר כ...',
  rent_as_info: 'ניתן להשכיר את הנכס כ...',
  apartment: 'דירה',
  unit: 'יחידת דיור',
  room: 'חדר',
  suite: 'סוויטה',
  tenancy_status: 'סטטוס השכרה',
  rented: 'מושכר',
  searching_for_tenants: 'בחיפוש שוכרים',
  free: 'פנוי',
  management_status: 'סטטוס ניהול',
  accompanied_first_year: 'ליווי שנה א',
  accompanied_second_year: 'ליווי שנה ב',
  accompanied_third_year: 'ליווי שנה ג',
  checking_year: 'שנת בדק',
  other: 'אחר',
  number_in_the_local_authority: 'מספר ברשות המקומית',
  rooms: 'חדרים',
  suites: 'סוויטות',
  toilets: 'שירותים',
  units: 'יחידות דיור',
  property_area: 'שטח במ"ר',
  court_yard: 'חצר',
  balcony: 'מרפסת',
  parking_type: 'סוג חניה',
  doubled: 'כפולה',
  underground: 'מתחת לאדמה',
  on_ground: 'על האדמה',
  in_shadow: 'בצל',
  // other:'',
  description_to_post: 'תיאור הדירה לפרסום',
  // address:
  existing_building: 'בניין קיים',
  // state
  // city
  // neighborhood
  // street
  // building
  apartment_num: 'דירה מס',
  at_floor: 'קומה',
  out_of: 'מתוך קומות',
  // elevators: 'Elevators',
  postal_code: 'מיקוד',
  // post_office_site: 'Post office site',
  distance: 'מרחק',
  // #-- RentPayment
  rent_payment: 'שכר דירה',
  payment_for_the_unit: 'תשלום ליחידה',
  // snap_to_index: 'Snap to index',
  frequency: 'תדירות',
  payments_methods: 'אמצעי תשלום',
  payment_day: 'יום התשלום',
  checks: 'צקים',
  checks_must_be_accepted: 'יש לקבל צקים במעמד חתימת החוזה',
  Last_check: 'צאק אחרון',
  in_a_non_standard_case: 'במקרה לא סטנדרטי',
  // #-- SafetyCheck
  list_is_empty: 'הרשימה ריקה',
  you_should_add_from: 'ניתן להוסיף מתוך...',
  safety_check: 'בטוחות',
  safeties_thats_required_for_rent: 'בטוחות נדרשות להשכרת הדירה',
  the_safety_type: 'סוג בטוחה',
  receiver: 'למי לשלם?',
  amount: 'סה"כ',
  valid_till: 'תקף עד',
  status: 'סטטוס',
  add_safety_check: 'הוסף בטוחה',
  // #-- apartment follower + manager
  maintenance: ' תחזוקה',
  add_follower: 'הוסף עוקב',
  add_manager: 'הוסף מנהל',
  managers: 'מנהלי הנכס',
  followers: 'עוקבים',
  // #-- service order permissions
  service_order_permissions: 'הרשאות לקריאות שירות',
  service_calls_that_can_be_opened: 'קריאות שירות שיהיה ניתן ליפתוח לדירה זו',
  electricity: 'חשמל',
  plummeting: 'אינסטלציה',
  security: 'בטחון',
  password_is_invalid: 'Password is invalid',
  // -- additional --------------------
  // -- furniture --------------------
  add_list: 'הוסף רשימה',
  Furniture_of_the_property: 'רשימות ריהוט',
  Furniture_lists_of_the_property:
    'רשימות ריהוט שנמצאות בנכס וניתן להוסיף להשכרה',
  excel_file: 'קובץ אקסל',
  import: 'ייבוא',
  export: 'ייצוא',
  current_price: 'מחיר נוחכי',
  cost: 'עלות',
  purchase_date: 'תאריך קנייה',
  item: 'פריט',
  // -- more details --------------------
  more_details_about_the_property: 'פרטים נוספים על הנכס',
  renovated: 'שיפוץ',
  renovation_started: 'תחחילת שיפוץ',
  renovation_ended: 'סיום שיפוץ',
  parking: 'חניה',
  shelter: 'מקלט',
  air_Conditioner: 'מזגן',
  access_for_disabled: 'נגיש',
  pandor_doors: 'דלתות פנדורה',
  for_couples: 'לזוגות',
  // elevators:'Elevators',
  Water_Heater: 'דוד שמש',
  wiFi: 'WiFi',
  optical_Fiber: 'סיב אופטי',
  smoking: 'עישון',
  kosher_kitchen: 'מטבח כשר',
  bars: 'סורגים',
  pets: 'חיות',
  // -- Meter Item (inner component) --------------------
  counter_type: 'סוג מונה',
  company_thats_owns_the_Meter: 'בחר חברה לשיוך העברת המונה',
  property_number: 'מספר נכס/ כרטיס לקוח',
  meter_location: 'מיקום המונה',
  last_call: 'קריאה אחרונה',
  current_call: 'קריאה נוכחית',
  main_user_integration_api_key: 'טוקן של המשתמש הראשי',
  delete_branch: 'מחק סניף',
  edit_branch: 'ערוך סניף',
  field_is_required: 'שדה זה חובה',
  // user:'user'
  responsible_for_the_meter: 'אחראי המונה',
  payment_transfer_is_required: 'נידרשת העברת מונים ',
  request_a_power_of_attorney_for_automatic_transfer:
    'בקש ייפוי כוח להעברה אוטומטית',
  make_a_payer_transfer_automatically: 'בצע העברת משלמים אוטומטית',
  archive_of_counter_readings: 'ארכיון קריאות מונים',
  transfer_Archive: 'ארכיון העברות',
  call_gas_company: 'התקשר לחברת גז',
  // #-- check-in-out
  // ------ sub-menu (check-in-out)
  statuses: 'סטטוסים',
  asset_condition: 'מצב הנכס',
  furniture: 'ריהוט',
  authorities: 'רשויות',
  summary: 'סיכום',
  summary_and_send: 'סיכום ושליחה',
  check_done: 'החלפה בוצעה',
  // ------ meters (check-in-out)
  end_the_process: 'סיים תהליך',
  water_meter: 'מונה מים',
  gas_meter: 'מונה גז',
  electricity_meter: 'מונה חשמל',
  municipality_meter: 'רשות מקומית',
  is_a_power_of_attorney_for_automatic_transfer:
    'האם יש ייפוי כוח להעברת משלמים',
  execute_the_transfer_process: 'בצע העברת משלמים',
  next: 'הבא',
  save: 'שמור',
  keys: 'מפתחות',
  move_to_archive_at_the_end_of_the_process: 'העבר לארכיון בסוף התהליך',
  // -- tenants ----------------------
  // -- assigned_property ----------
  assigned_property: 'נכסים משוייכים',
  asset_1: 'נכס 1',
  asset_2: 'נכס 2',
  // asset:'Asset',
  // -- Personal info ------------------------
  personal_info: 'מידע אישי',
  personal_information: 'מידע אישי',
  name: 'שם',
  last_name: 'שם משפחה',
  national_id: 'תעודת זהות',
  tenant_type: 'סיווג השוכר',
  share_to: 'שתף ל:',
  // phone
  second_phone: 'טלפון נוסף',
  // mail
  gender: 'מין',
  date_of_birth: 'תאריך לידה',
  not_valid: 'פג תוקף',
  // invite_user
  // upload_an_avatar: 'Upload an avatar',
  max_file_to_be_uploaded: 'גודל הקובץ המירבי להעלאה הוא 20 MB',
  identity_confirmation: 'אימות הזהות',
  identity_not_verified_you_can_do_it_later:
    'הזהות לא מאומתת, ניתן לחזור ולאמת בכל רגע',
  document_type: 'סוג המסמך',
  number: 'מספר',
  date_and_time: 'תאריך ושעה',
  date_of_issue: 'תאריך מתן המסמך',
  // valid_till:'Valid till',
  driving_license: 'רשיון נהיגה',
  passport_id: 'מספר דרכון',
  // other
  // name
  another_license: 'מסמך אחר',
  add_other: 'הוסף אחר',
  add_passport: 'הוסף דרכון',
  add_license: 'הוסף רשיון',
  add_national_ID: 'הוסף תעודת זהות',
  national_ID: 'תעודת זהות',
  // --- agents
  // agents:'Agents',
  agent_that_found_the_tenant: 'הסוכן שמצא את השוכרים',
  // --address
  old_address_of_the_user: 'כתובת ישנה של השוכר',
  work: 'עבודה (אופציונלי)',
  work_sphere: 'תחום',
  Salary: 'משכורת',
  // -- Aditional info ------------------------
  // -- company info ------
  company_information_: 'מידע על החברה',
  company_subtitle_info: 'במקרה בו החברה היא הגוף השוכר את הנכס',
  check_out_the_company: 'בדוק את נתוני החברה',
  company_name_: 'שם החברה',
  company_number: 'מספר ח.פ',
  business_sector: 'תחום עיסוק',
  year_cycle: 'מחזור שנתי',
  economic_strength: 'איתנות כלכלית',
  // - Auth ----------------------
  // -- signin
  redirecting_to_the_document: 'מועבר למסמך',
  redirecting_to_the_verification: 'מועבר להמשך הזדהות',
  welcome_to_sign_fast: 'ברוך הבא לסייני',
  first_we_need_to_be_sure_: 'קודם כל נצטרך לוודא שזה אתה',
  this_number_is_not_registered: 'המסספר הזה לא קיים במערכת, הירשמו תחילה',
  this_email_is_not_registered: 'המייל הזה לא קיים במערכת, הירשם תחילה',
  please_try_one_more_time_: 'נסה פעם נוספת, שם משתמש או הסיסמה שגויים',
  email_is_already_registered: 'מייל זה כבר רשום במערכת',
  number_is_already_registered: 'מסר טלפון זה כבר רשום במערכת',
  sign_in: 'התחבר',
  skip_sign: 'דלג וחתום',
  skip_generate_password: 'דלג וצור סיסמה',
  log_in: 'כניסה',
  account_export: 'יצוא חשבון',
  reset_password: 'אפס סיסמה',
  name_of_branch_office: 'שם החברה: ',
  export_my_data_in_csv: 'יצא את המידע שלי ב CSV',
  birth_date: 'תאריך לידה',
  image_of_organization: 'תמונה של הארגון',
  log_in_with: 'כנס באמצעות  ',
  sign_up_with: 'הרשמה בעזרת',
  quick_log_in: 'הרשמה מהירה',
  forgot_password: 'שכחתי סיסמה',
  password: 'סיסמה',
  email: 'מייל',
  email_or_phone: 'מייל או מספר טלפון',
  mail_you_registered_with: 'מייל שאיתו נרשמתם',
  phone: 'טלפון',
  stay_in: 'השאר אותי מחובר',
  back_to_main_page: 'חזור לדף הבית',
  registration: 'הרשמה',
  see_feature_list: 'מחירון',
  redirecting_to_new_asset: 'אתם מועברים ליצירת דירה חדשה ',
  welcome_to_RentIL: 'ברוכים הבאים לרנטיל',
  click_here_if_you_dont_get_redirected: 'לחצו כאן אם אתם לא מועברים',
  message_was_sent_to_your_company: 'נשלחה הודעה לחברת הניהול שלך',
  redirecting_to_home_page: 'אתם מועברים למסך הראשי',
  forgot_password_q: 'שכחתם את הסיסמה?',
  create_password: 'צור סיסמה',
  // registration:'',
  write_your_mail_or_phone_number_to_get_link_to_restore_your_password:
    'הזן את המייל/טלפון שלך כדי לקבל קישור לאיפוס הסיסמה',
  send: 'שלח',
  or_registration_by: 'או הירשם באמצעות',
  repeat_password: 'אימות סיסמה',
  back: 'חזור',
  repeat: 'חוזר',
  change_Password: 'החלפת סיסמה',
  apply: 'אישור',
  skip_and_go_to_home_page: 'דלגו ועבורו לדף הבית',
  by_clicking_continue: 'בלחיצה על הכפתור אתם מאשרים את ',
  terms_and_conditions: 'תנאי השירות',
  and: 'ו',
  privacy_policy: 'מדיניות הפרטיות ',
  skip_and_sign: 'דלגו וחתומו',
  current_password: 'סיסמה נוכחית',
  new_password: 'סיסמה חדשה',
  // sign_fast_change
  the_right_: 'הדרך הנכונה לחתום היום על מסמכים',
  only_want_to_sign_a_document: 'רוצה לחתום על מסמך בעצמי',
  go_to_signy: 'עבור לסייני',
  signy_advanced_PDF_viewer: 'סייני ',
  signy_advanced_PDF_creator: 'העורך של סייני',
  // -- choose role
  who_are_you: 'באיזה תפקיד תרצה להיכנס?',
  apartment_owner: 'בעל נכס',
  property_manager: 'מנהל נכסים',
  communal_building_manager: 'ועד בית',
  property_management_company_CEO: 'מנהל חברת ניהול נכסים',
  property_agent: 'סוכן נדל"ן',
  // -- How do you manage the assets?
  how_do_you_manage_the_assets_q: 'איך אתה מנהל את הנכסים שלך?',
  managing_by_myself: 'מנהל בעצמי',
  company_is_managing_for_me: 'חברת ניהול מנהל את נכסיי',
  // -- CompanyInvitation
  invite_the_company_to_enhance_clarity_between_you:
    'הזמן את חברת ניהול הנכסים כדי ליצור שקיפות בינכם',
  existing_company: 'חברה קיימת',
  new_company: 'חברה חדשה',
  ask_company_to_manage_my_assets: 'בקש מהחברה לנהל את נכסיי',
  company_email: 'מייל של בעל החברה',
  company_phone: 'טלפון החברה',
  share_link: 'שתף קישור',
  scheduled_send: 'שליחה מתוזמנת',
  big_send: 'שלח',
  // -- email_sent
  weve_sent_you_an_email: 'שלחנו לך הודע עם קישור כניסה למערכת',
  go_to_your_l_to_open_the_link: 'גש לתיבת הדואר שלך ולחץ על הקישור',
  // or: 'Or',
  paste_here_6_digits_from_SMS_email_you_received:
    'הקלד כאן את 6 הספרות שקיבלת במייל או בSMS',
  click_here_if_you_dont_receive_the_email: 'לחץ כאן אם לא קיבלת קישור למייל',
  no_drafts: 'אין טיוטות',
  inbox_is_empty: 'אין מסמכים לחתימה עדיין',
  nothing_was_sent: 'עדיין לא נישלח כלום',
  here_will_be_the_drafts:
    'כאן יהיו מסמכים שהתחלתם לערוך ולא סיימתם, או כאלו שלא נחתמו וניתן עדיין לערוך את מכתב השליחה',
  here_will_be_the_sent: 'כאן יופיעו המסמכים ששלחו לך.',
  here_will_be_you_sent: 'כאן יופיעו המסמכים ששחלת כבר.',
  arch_empty: 'הארכיון ריק',
  here_will_be_archive: 'כאן יופיעו המסמכים שהועברו לארכיון.',
  nothing_was_deleted: 'שום דבר לא נימחק עדיין',
  here_will_be_deleted:
    'כאן יופיעו המסמכים שנמחקו. הם ישמרו במערכת ל30 יום ולאח מכן ימחקו לצמיתות.',
  no_actions: 'אין מסמכים המחכים לפעולה',
  here_will_actions: 'כאן יופיעו המסמכים שמחכים לפעולה מצדך',
  no_waiting: 'אין עדיין מסמכים הממתינים לחתימה',
  here_will_waiting: 'כאן יופיעו המסמכים שמחכים לחתימות',
  no_urgent: 'אין מסמכים דחופים',
  here_will_urgent: 'כאן יופיעו מסמכים שמצריכים חתימה דחופה, תוך 5 ימי עסקים',
  no_for_government: 'מסמכים ממשלתיים',
  here_will_for_government: 'כאן יופיעו המסמכים שמיועדים למשרדי ממשלה.',
  no_completed: 'אין עדיין מסמכים שהושלמו ',
  here_will_completed: 'כאן יופיעו מסמכים שנחתמו וסיימו את כל התהליך',
  no_custom: 'אין עדיין מסמכים בתקייה זו',
  here_will_custom: 'כאן יופיעו המסמכים שיועברו לתקייה זו',
  drafts: 'טיוטה',
  inbox: 'מסמכים ניכנסים',
  sent: 'נשלחו',
  archive: 'ארכיון',
  actions_required: 'פעולה נידרשת',
  waiting_for_others: 'ממתין לאחרים',
  expiring_soon: 'מועד סיום מתקרב',
  for_government: 'ממשלתי',
  completed: 'הושלם',
  folder: 'תקייה',
  file_storage: 'אחסון',
  export_html: 'יצא HTML',
  duration: 'משך',
  attach_file: 'הוסף קובץ',
  size_any_type: 'גודל הקובץ 50mb מכל סוג',
  optional_informaition: 'מידע אופציונלי',
  view_plans: 'הצג מסלולים',
  plans: 'מסלולים',
  to_change_fields_types_: 'לאפשרויות נוספות, שינוי סוג השדה וחיבור לממשקים',
  you_must_fill_out_all_: 'יש למלא את כל שדות החובה',
  contact_information: 'מידע על איש הקשר',
  user_color: 'צבע המשתמש',
  validation_type: 'סוג אימות',
  no_validation: 'ללא אימות נתונים',
  password_protection: 'הגנה בעזרת סיסמה',
  remind_time: 'זמן תזכורת',
  cannot_edit_document_opened: 'לא ניתן לערוך, המסמך ניפתח ע״י אחד החותמים',
  document_will_expire_at: 'תוקף המסמך יפוג ב:',
  all_users_fill_out_the_single_origin_of_the_document:
    'כל המשתמשים חותמים על מסמך',
  single_message_for_all: 'הודעה משותפת לכולם',
  personal_messages: 'הודעה נפרדת לכל משתמש',
  sms_notifications: 'תזכורות SMS',
  automatically_send_sms:
    'שלח הודעות אוטומטיות לחותמים ולבעלים אודות התקדמות תהליך החתימה',
  notification_language: 'שפת ההתראות',
  system_language: 'שפת המערכת',
  free_resources_left: '%%% מנפח החבילה נוצלו',
  default_document_settings: 'הגדרות ברירת מחדל של המסמכים',
  profile: 'פרופיל',
  saved: 'נישמר',
  organizations: 'ארגונים',
  organizations_general_tab: 'כללי',
  organizations_branches_tab: 'עסקים',
  organizations_add_branch: 'הוסף עסק',
  organizations_add_branch_name: 'שם העסק',
  you_can_not_create_a_organization_: 'אינך יכול ליצור חברה כי היא כבר קיימת',
  you_can_not_create_a_organization_for_now: 'אינך יכול ליצור חברה כרגע',
  if_you_want_contact_us:
    'אם אתה מועניין ליצור חברה ולקבל יכולות נוסופות צור איתנו קשר במייל',

  name_of_your_company_is: 'שם החברה הוא',
  // editor settings
  settings: 'הגדרות',
  add_photo: 'הוסף תמונה',
  type_name: 'סוג שם',
  visa_card: 'כרטיס אשראי',
  changes_are_saved_automatically: 'השינויים נישמרים אוטומטית',
  expiration: 'פקיעת תוקף',
  expiration_date: 'תאריך פקיעת תוקף',
  document_will_expire_in: 'תוקף המסמך יפקע בעוד',
  reminders: 'תזכורות',
  send_a_reminder_: 'שלח תזכורת כל',
  notification: 'תזכורות',
  color_the_tab: 'צבע',
  you_will_recieve_email_: 'אתם תקבלו מייל ברגע שהחותמים יחתמו על המסמך',
  uuid_recomeded_: 'UUID (מומלץ שיהיה דלוק)',
  id_number: 'מספר תעודת זהות',
  show_unique_:
    'הראה מזהה יחודי למסך, מומלץ להשאיר את האפציפיה הזאת דלוקה כדי שתוכל לאמת את המסמך דיגיטלית בכל עת ע״י QR קוד',
  signature_verification_: 'סיסמת אימות חתימה (מומלץ להדליק אפשרות זו)',
  digitally_verify_:
    'אמת דיגיטלית מסמך מודפס בעזרת qr קוד המוטבע בתחתית המסמך.',
  email_customization: 'עיצוב מייל',
  email_branding: 'מיתוג מייל',
  include_logo:
    'הוסף את הלוגו ואת שם החברה שלך, העלה אותו בפורמטים הבאים: PNG, JPG, SVG.',
  logo: 'לוגו',
  drug_and_drop: 'גרור ושחרר',
  or_upload_a_file: 'או העלה קובץ',
  recommended_size_: 'גודל מומלץ 300 x150 פיקסלים',
  two_factor_auth_: 'אימות דו שלבי, אם המשתמש בתוך המערכת',
  video_of_user_: 'החותם מעלה וידאו שלו אומר את המשפט',
  selfie_with_own_id: 'החותן יעלה סלפי שלו יחד עם התעודה המזהה',
  signature_type: 'סוג חתימה',
  add_recipients_who_: 'לא ניבחרו חותמים',
  // editor left panel
  show_all: 'הצג הכל',
  show_less: 'הצג פחות',
  recipients: 'חותמים',
  text: 'טקסט',
  signature: 'חתימה',
  date: 'תאריך',
  already_created: 'חתימות קיימות',
  placeholder: 'ממלא מקום',
  hint: 'רמז',
  letters_quantity: 'כמות אותיות',
  dropdown_options: 'תיבת בחירה',
  upgrade_to_pro_now: 'שדרג מסלול לpro',
  dropdown: 'רשימה נפתחת',
  attachment: 'צירוף קבצים',
  checkbox: 'תיבת סימון',
  radiobutton: 'כפתור רדיו',
  rating: 'דֵרוּג',
  // ToolsEditorPanel
  simple: 'פשוטה',
  digital: 'דיגיטלית',
  fields: 'שדות',
  required: 'חובה',
  // SignatureModal
  draw: 'צייר חתימה',
  upload: 'העלה',
  lowercase_upload: 'העלה',
  type_signature: 'אותיות תחיליות',
  delete_all_signatures: 'מחק את כל החתימות',
  apply_and_sign: 'הוסף חתימה',
  buy_more_signatures: 'קנה עוד חתימות',
  creating_your_signature: 'צור חתימה שלך',
  move_contact_to_group: 'העבר איש קשר לקבוצה',
  move_contact_to_active: 'הםוך איש קשר לפעיל',
  move_contacts_to_active: 'הפוך אנשי קשר לפעילים',
  move_contact_to_archive: 'העבר איש קשר לארכיון',
  move_contacts_to_archive: 'העבר אנשי קשר לארכיון',
  delete_contact: 'מחק איש קשר',
  // editor right panel
  assign_all_recipients_to_all_fields: 'הוסף את כל החותמים לכל השדות',
  all_fields_: 'הוסף ושייך לכל השדות',
  all_fields: 'כל השדות',
  sign_order: 'סדר חתימות',
  remove_all_recipients_from_all_fields: 'מחק את החותמים מכל השדות',
  assign: 'שייך',
  remove: 'מחק',
  add_from_google_contacts: 'הוסף מתוך אנשי קשר של גוגל',
  add_me_to_all_fields: 'הוסף אותי לכל השדות',
  remove_me_from_all_fields: 'הסר אותי מכל השדות',

  recipient: 'חותם',
  add_me: 'הוסף אותי',
  recipients_are_those_:
    'כל מי שמקבל את הקישור יוכל לפתוח אותו אם תהפוך אותו לפומבי, אם יהיו שדות משוייכים לחותם ספציפי רק הוא יראה את אותו שדה.',
  you: 'אתה',
  validator: 'מאמת',
  persons_should_sign_: 'חותימם צריכים לחתום בו זמנית',
  new: 'חדש',
  add_all_from_search: 'הוסף הכל מתוך החיפוש',
  // signed document
  system_accept_only_: 'המערכת מקבלת מסמכים בפורמט pdf בלבד',
  file_size_should_be_: 'גודל הקובץ חייב ליהיות קטן מ-200MB',
  document_signed: 'המסמך נחתם בהצלחה',
  a_file: 'קובץ',
  download_a_copy_signed_: 'הורד עותק של המסמך החתום',
  copy_link_to_file: 'העתק קישור לקובץ',
  create_document_to_sign: 'צור מסמך לחתימה',
  create_empty: 'צור ריק',
  you_can_find_this_: 'אפשר למצוא את זה ב מסמכים > מסמכים חתומים',
  hello_you_got_to_sign_fast_:
    'שלום! הגעתם לסייני! כדי לחתום על המסמך, התחברו או צרו משתמש',
  //ContextHeaderPrivate
  public: 'פומבי',
  anyone_with_the_link_: 'כל מי שיש לו את הקישור יוכל לגשת למסמך',
  private: 'פרטי',
  only_listed_recipients_: 'רק החותמים שהוספו למסמך יוכלו לגשת אליו',
  sharing: 'שיתוף',
  //empty
  no_drafts_yet: 'אין טיוטות בינתיים',
  drafts_text:
    'Here will be the files you started to edit and didn’t send. Upload and edit file here',
  //headerSignFast
  url: 'URL',
  document_settings: 'הגדרות',
  coming_soon: 'בקרוב',
  from_drive: 'מתוך drive',
  from_device: 'מתוך המכשיר',
  preview: 'תצוגה מקדימה',
  previous_page: 'עמוד קודם',
  next_page: 'עמוד הבא',
  fit_to_width: 'התאם לרוחב הסך',
  fit_to_height: 'התאם לאורך המסך',
  preview_for_users: 'תצוגה מקדימה (חותם)',
  //subhaderEditor
  zoom_in: 'קרב',
  zoom_out: 'הרחק',
  // Signy
  signy: 'סייני',
  document_is_protected_:
    'המסמך מוגן בסיסמה, אנא הכנס סיסמה כדי לקבל אליו גישה',
  this_code_were_given_to_you_:
    'הסיסמה ניתנה לך ע״י הבעלים של המסמך, פנה עליו אם לא קיבלת סיסמה',
  you_were_invited_to_sign_: 'הוזמנת לחתום על המסמך עד',
  video_validation: 'אימות בעזרת וידאו',
  please_record_yourself_by_telling_:
    'בבקשה צלמ.י את עצמך אומר.ת את המשפט: ״חתימה דיגיטלית״',
  national_id_verification: 'אימות תעודת זהות',
  this_id_its_temporary_once_:
    'צילום תעודה נישמר עצלינו עד לבדיקה ואימות על ידי נציג החברה. ברגע שנאמת את התעודה היא תמחק מבסיס הנתונים',
  national_id_photo: 'צילום תעודת זהות',
  selfie_with_document: 'סלפי עם התעודה',
  // uploaded: 'Uploaded',
  sms_validation: 'אימות בעזרת sms',
  send_verification_code: 'שלח קוד אימות',
  we_sent_you_an_sms_to: 'שלחנו לך sms',
  resend_code: 'שלח קוד שוב',
  please_enter_some_details_about_yourself: 'בבקשה הכניסו כמה נתונים',
  this_image_as_valid_as_considered_: 'החתימה שתצייר תוערך בחוק כחתימה אמיתית',
  by_clicking_next_above_:
    'בלחיצה על כפתור ״הבא״ אתה מאשר כי ראיתה את כל הקבצים המצורפים',
  ask_for_review: 'בקש בחינה',
  decline_document: 'סרב לחתום על המסמך',
  try_to_create_a_document_:
    'צור מסמך במערכת שלנו, וקבל 10 מסמכים ראשונים חינם',
  enjoyed_the_experience: 'נהינת מהתהליך?',
  declining: 'סירוב חתימה',
  // if_dimension_is_dropdown_it_:
  // 'If dimension is dropdown, it will drop upwards (fly), or it should be placed weird, aside from the text frame,',
  registration_page: 'Registration page',
  document_design_fg_: 'Document design.fg, 15 mb',
  open_link: 'פתח קישור',
  document_fields: 'שדות המסמך',
  //signy contacts
  i_dont_know_yet: 'I don`t know yet',
  // explorer => contacts
  field_id: 'מזהה שדה',
  contacts: 'אנשי קשר',
  contact: 'איש קשר',
  make_favorite: 'הוסף למועדפים',
  remove_favorite: 'הסר ממועדפים',
  add_contact: 'הוסף איש קשר',
  new_contact: 'צור איש קשר',
  use_this_number_for_whatsapp: 'השמש במספר זה לוואטסאפ',
  telegram: 'טלגרם',
  style: 'סגנון',
  reason_for_declining: 'סיבת סירוב החתימה',
  groups: 'קבוצות',
  signing_order: 'סבב חתימות',
  group_members: 'אנשי קשר בקבוצה',
  import_csv: 'ייבא מתוך CSV',
  from_google: 'יבא מתוך Google',
  create: 'צור איש קשר',
  ddyymm: 'DD.YY.MM',
  ddmmyy: 'DD/MM/YY',
  first_name: 'שם פרטי',
  sur_name: 'שם משפחה',
  add_at_least_one_recipient_:
    'שים לב כי יש להוסיף לפחות שדות לחתימה ולהוסיף להם משתמשים כדי להתקדם',
  upload_contacts_from_csv: 'העלה אנשי קשר מתוך CSV',
  upload_my_contacts: 'העלה את אנשי הקשר שלי',
  download_csv_template: 'הורד תבנית CSV',
  google_contact_migration: 'יבוא אנשי קשר מתוך גוגל',
  add_contacts_to_employees_: 'הוסף אנשרי קשר לקבוצה',
  custom_group: 'קבוצות מותאמת אישית',
  all_contacts: 'כל אנשי הקשר',
  google_contacts: 'אנשי קשר מגוגל',
  my_team: 'הצוות שלי',
  deleted: 'מחוקים',
  add_groups_to_employees_: 'הוסף קבוצות לעובדים',
  all_groups: 'כל הקבוצות',
  employees: 'עובדים',
  uploaded: 'הועלו',
  farovites: 'מועדפים',
  delete_group: 'מחק קבוצה',
  no_contacts_yet: 'אין אנשי קשר עדיין',
  no_contacts: 'אין אנשי קשר',
  not_agree_with_content: 'לא מסכים עם התוכן ',
  something_is_missing: 'משהו חסר',
  document_name: 'שם המסמך',
  you_can_upload_your_contacts_: 'אפשר לעלות את אנשי הקשר כאן',
  whatsapp: 'וואטסאפ',
  telegram_username: 'טלגרם',
  mail: 'מייל',
  favorite: 'מועדפים',
  group_created: 'קבוצה נוצרה',
  all_documents: 'כל המסמכים',
  responsibles: 'אחראיים',
  rows_selected: 'שורות נבחרו',
  select_move: 'הזזה',
  forms: 'טפסים',
  use_template: 'השתמש בתבנית',
  created: 'נוצר',
  error_occurred_: 'שגיאה, המסמך לא תקין',
  // attachments: 'Attachments',
  dublicate: 'שכפל',
  edit: 'ערוך',
  // active: 'Active',
  no_teams: 'אין קבוצות עדיין',
  no_uploaded: 'אין העלאות עדיין',
  no_archieve: 'אין כלום בארכיון עדיין',
  no_attachments: 'אין קבצים מצורפים',
  drop: 'שחרר את ',
  pdf_file: 'קובץ ה- PDF',
  here: 'כאן',
  custom_folders: 'תקיות',
  search_table: 'חפש בטבלה',
  search_users: 'חפש משתמשים',
  viewed: 'נצפה',
  signers: 'חותמים',
  signing_status: 'סטטוס חתימה',
  // archive: 'Archive',
  // deleted: 'Deleted',
  custom_folders_forms: 'תקיות',
  send_to_sign: 'שלח לחתימה',
  archive_document: 'העבר לארכיון',
  delete_document: 'מחק',
  creator_name: 'שם הבעלים',
  no_signatories: 'אין חותמים',
  sign: 'חתום',
  user_settings: '',
  download: 'הורדה',
  download_now: 'הורד',
  loading: 'טוען...',
  checkbox_list: 'תיבות סימון',
  radiobutton_list: 'כפתורי רדיו',
  reject: 'סרב',
  role: 'תפקיד',
  comming_soon: 'בקרוב',
  not_recieved: 'לא התקבל',
  // sent: 'Sent',
  not_sent: 'לא נשלח',
  opened: 'נפתח',
  read: 'נקרא',
  cancel: 'בטל',
  // preview: 'Preview',
  new_folder: 'תקייה חדשה',
  pending: 'ממתין',
  signed: 'נחתם',
  // completed: 'Completed',
  // cancelled: '',
  document: 'מסמך',
  message: 'הודעה',
  checking: 'בדיקה',
  share: 'שיתוף',
  message_for_all: 'הודעה משותפת',
  pdf_viewer: 'מציג PDF',
  lowercase_fields: 'שדות',
  all_fields_are_filled: 'כל השדות מלאים',
  initials: 'אתיות תחיליות',
  asked_for_review: 'דורש בדיקה',
  rejected: 'סורב',
  // file => id => checking
  dimensions: 'Dimensions',
  finished_steps: 'שלבים שבוצעו',
  next_steps: 'שלבים הבאים',
  deadline: 'תאריך אחרון',
  vacation: 'Vacation',
  figma_file: 'Figma file',
  party_address: 'Party address',
  device: 'מכשיר',
  google_drive: 'גוגל דרייב',
  from_template: 'מתוך תבנית',
  // right panel
  templates: 'תבניות',
  you_must_fill_out_all_required_field: 'יש למלא את כל השדות הדרושים',
  sample: 'דוגמה',
  // editor
  email_address_you_entered_: 'הדוא״ל שהכנסת אינו תקין',
  its_need_to_be_a_valid_: 'יש להכניס תז תקין',
  clear_sign: 'נקה חתימה',
  // right panel
  pen: 'עפרון',
  assign_all_recipients_: 'האם לשייך את כל החותמים לכל השדות?',
  it_cannot_be_toggled_back_:
    'פעולה זאת לא יכולה ליהיות מבוטלת, כדי לשייך חותמים שונים לשדות שונים ,תצטרכו לערוך את השדות ידנית',
  remove_all_recipients_:
    'האם אתם בטוחים שאתם רוצים למחוק את את כל החותמים מכל השדות?',
  they_will_stay_in_the_: 'הם ישארו ברשימה, אבל לא יהיו משוייים לשום שדה',
  drag_recipient_to_add_: 'גרור לכאן חום כדי להוסיף תור',
  all_recipients_were_removed_: 'כל המשתמשים הוסרו בהצלחה',
  drag_and_drop: 'גרור ושחרר',
  // settings
  all_users_fill_out_the_single: 'כל החותמים ממלאים את את אותו העותק של המסמך',
  english: 'English',
  hebrew: 'עברית',
  // company_name: 'Company name',
  remove_group: 'הסר קבוצה',
  remove_recipient: 'הסר חותם',
  viewer: 'צופה',
  or: 'או',
  // validator: 'Validator',
  signer: 'חותם',
  validator_will_review_:
    'מאמת המסמך יבדוק את המסמך לתקינותו, ויכול לסרב חתימה או לבקש לשנות את המסמך',
  // validator_will_review_: 'The validator will review the document content and can validate or revoke the signature request',
  viewer_recieve_a_copy_:
    'הצופה יקבל עותק מלא של המסמך אך לא יכול לחתום עליו או לתת הערות',
  validator_caps: 'מאשר',
  signer_caps: 'חותם',
  viewer_caps: 'צופה',
  these_preferences_will_apply_:
    'הגדרות אלו יחולו על כל החותמים על המסמך, ניתן לשנות הגדרות לכל חותם בנפרד דרך כפתור עריכת חותם',
  password_was_generated_: 'הסיסמה נוצרה אוטמטית, ניתן לשנות אותה ידנית',
  passcode_should_be_minimum_: 'הסיסמה צריכה ליהיות לפחות 4 תווים',
  create_qr: 'צור קוד QR',
  link_reciviers: 'מקבלי הטפסים לחתימה',
  qr: 'QR',
  email_or_phone_required: 'דרוש לפחות מייל או טלפון',
  // user: 'user',
  // created: 'created',
  //##### Alerts #####
  contact_is_favorite: 'איש הקשר נוסף למועדפים',
  contact_is_not_favorite: 'איש הקשר הוסר ממועדפים',
  contacts_updated: 'אנשי הקשר עודכנו בהצלחה',
  contacts_moved: 'אנשי הקשר הועברו בצלחה',
  document_was_downloaded_: 'המסמך הורד בהצלחה',
  error_document_was_protected: 'ה-PDF מוגן בסיסמה, אנחנו לא יכולים לפתוח אותו',
  invalid_code: 'קוד לא תקין',
  clear_all: 'נקה הכל',
  color_select: 'בחירת צבע',
  success: 'הפעולה בוצעה בהצלחה',
  enter_using: 'היכנס באמצעות',
  add_group: 'הוסף קבוצה',
  create_field: 'צור שדה',
  fields_summary: 'סיכום שדות',
  drawing: 'שרטוט',
  fields_in_the_doc: 'שדות במסמך',
  pdf_docx: 'PDF, DOCX',
  no_groups_yet: 'אין קבוצות עדיין',
  language_changed: 'השפה שונתה',
  folder_created: 'תקייה נוצרה בהצלחה',
  document_updated: 'המסמך עודכן בהצלחה',
  documents_updated: 'המסמכים עודכנו בהצלחה',
  all_your_contacts_: 'כל אנשי הקשר שלך סונכרנו בהצלחה',
  all_recipients_were_: 'כל החותמים הוסרו מכל השדות בהצלחה',
  copied_private: 'הקישור האישי הועתק בהצלחה',
  copied_general_code: '׳קישור כללי הועתק בהצלחה',

  text_input: 'שדה טקסט',
  date_input: 'שדה תאריך',
  // password
  password_is_required: 'דרושה סיסמה',
  password_has_to_include: 'הסיסמה צריכה לכלול',
  between_8_and_20: 'בין 8 ל20 תווים',
  at_least_one_lowercase_English_: 'לפחות אות אחת קטנה באנגלית (a-z)',
  at_least_one_uppercase_English_: 'לפחות אות אחת גדולה באנגלית (A-Z)',
  at_least_one_specific_character: 'לפחות סימן מיוחד אחד(!@#$%^&*()-+/{})',
  do_not_use_password_that_: 'אין להשתמש בסיסמה שהייתה בשימוש',
  attention: 'שים לב',
  do_not_use_the_date_of_the_: 'אין להשתמש בתעודת זהות, תאריך לידה, או שם',
  do_not_repeat_a_character_more_: 'אין לחזור על אותו תו יותר מפעמיים',
  at_least_one_number: ' לפחות מספר אחד (0-9)',
  dear_user_due_to_many_attempts_:
    'משתמש יקר, אחרי ביצוע ניסיונות רבים האתר נחסם ל5 דקות',
  // sign_in: 'Sign In',
  we_have_sent_you_as_SMS_: 'שלחנו לך SMS למספר ***',
  we_have_sent_you_as_EMAIL_: 'שלחנו לך EMAIL ל ***',
  _download_the_csv_template:
    '1. הורד את הטמפלט. 2. מלא את הקובץ לפי הדוגמאות שקיימות בו ולאחר מכן מחק אותן. 3. העלה חזרה לכאן את  הקובץ שים לב שהוא בפורמט הנכון ',
  save_as_template: 'שמור את התבנית',
  open_editor: 'פתח עורך',
  attachments: 'קבצים מצורפים',
  unlock: 'פתח',
  cancelled: 'מבוטל',
  undo: 'בטל',
  redo: 'החזר',
  print: 'הדפס',
  shared: 'המסמךנשלח לחותמים',
  invert_background: 'הפוך רקע',
  scheduleShared: 'ניקבע זמן לשיתוף המסמך',
  chooseSignerMethods: 'בחרו אופן שליחה תחילה',
  add_signatories: 'יש לעבור למסמך ולהוסיף חותמים תחילה',
  copy_qr_and_link: 'העתק קוד QR וקישור',
  active_templates: 'תבניות פעילות',
  active: 'פעיל',
  copy: 'העתק',
  tags: 'תוויות',
  show_lables_in_document: 'הצג תגיות במסמך',
  uppercase_signing_status: 'סטטוס מסמכים',
  uppercase_groups: 'קבוצות',
  last_sharing: 'משות]ים אחרונים',
  shared_with: 'שותף עם',
  adding_rows_from_database: 'הוסת שורות מדאטה בייס',
  create_document: 'צור מסמך',
  exit_preview: 'צא מתצוגה מקדימה',
  // not-allowed
  view_this_document: 'אין גישה למסמך',
  to_get_access: 'צרו קשר עם הבעלים של המסמךלקבלת גישה',
  create_your_document: 'צור מסמך משלך',
  create_your_signature: 'צור חתימה',
  // signed-document page
  already_signed: 'כבר חתמת על מסמך זה:)',
  documentFields: '{length} / {index} שדות',
  documentFieldsList: '{count} שדות במסמך',

  all_signers: 'כל החותמים',

  this_input_is_required: 'שדה זה הוא חובה',
  email_address_you_entered_is_invalid: ' נא להזין כתובת דואר אלקטרוני תקינה',
  its_need_israel_id_format: 'נא להזין תעודת זהות תקינה',
  phone_is_invalid: 'נא להזין מספר טלפון תקין',
  email_value_is_not_valid: 'נא להזין כתובת דואר אלקטרוני תקינה',
  is_integrated_with_third_party: 'אינטגרציה',

  you_received_doc_sign: 'קיבלת מסמך לחתימה!',
  continue_with: 'המשך עם',
  continue: 'המשך',

  //modal
  // #-- footer
  // #--- sub-menu building
  // #--- sub menu (tenant)
  // #--- sub menu (tenancy)
  // apartments boards
  // assets:'נכסים',
  // #--- side-bar
  // #--- address
  // #--- payments
  // #--- house keeper - Payment information
  // -- general -----------------------
  // adderss:
  //   post_office_site: 'מיקוד לפי כתובת',
  /// ----------------------------------
  // -- Additional info ------------------------
  //settings Tabs
  files: 'קבצים',
  invalid_otp_code: 'קוד חד פעמי שגוי',

  no_internet_connection: 'אין חיבור לאינטרנט',

  //errors
  an_error_occurred: 'משהו השתשב...',
}
