import en from './lang/en'
import he from './lang/he'

export default defineI18nConfig(() => ({
  legacy: false,
  globalInjection: true,
  strategy: 'no-prefix',
  locale: 'en',
  messages: {
    en,
    he,
  },
}))
